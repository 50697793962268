<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <el-form label-position="right" label-width="80px" :model="form">
          <el-form-item label="用户账号:">
            <p>{{ userinfo.mobile }}</p>
          </el-form-item>
          <el-form-item label="昵称:">
            <el-input v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="手机号码:">
            <p>{{ userinfo.mobile }}</p>
          </el-form-item>
          <!-- <el-form-item label="QQ号码:">
            <el-input v-model="form.region"></el-input>
          </el-form-item>
          <el-form-item label="微信号:">
            <el-input v-model="form.type"></el-input>
          </el-form-item> -->
          <el-form-item>
            <div class="btn" @click="edit">保存修改</div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { getUser, setUser } from "@/plugins/auth.js";
import { api_updateUserImg } from "@/apis/apis";

// import { api_queryUser } from "@/apis/apis";
import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    return {
      form: {
        nickname: "",
        region: "",
        type: "",
      },
      userinfo: {},
    };
  },
  created() {
    this.userinfo = getUser();
    this.form.nickname = this.userinfo.nickname;
  },
  computed: {
    info() {
      return this.$store.state.userstore.userInfo;
    },
  },
  mounted() {
    //  console.log(this.$store.state.userstore.userInfo);
  },

  methods: {
    edit() {
      console.log();
      api_updateUserImg({
        id: getUser().id,
        nickName: this.form.name,
      }).then((res) => {
        if (res.data.code == 200) {
          setUser(
            getUser().token,
            getUser().id,
            getUser().avatar,
            getUser().mobile,
            this.form.nickname
          );
          console.log(getUser());
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    flex: 1;
    border: 1px solid #f2f2f2;
    .el-form {
      width: 390px;
      margin-top: 60px;
      margin-left: 80px;
      margin-bottom: 100px;
      .btn {
        height: 36px;
        width: 120px;
        line-height: 36px;
        text-align: center;
        margin-left: 100px;
        background-color: #2d69f9;
        color: #fff;
      }
    }
  }
}
</style>